<template>
  <div class="product-item text-center" v-for="(item, i) in Items" :key="i" @mouseover="showByIndex = i"
    @mouseout="showByIndex = null">
    <a :href="item.url">
      <div class="product-img">
        <img v-if="showByIndex === i" v-show="showByIndex === i"
          :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_030A.jpg`"
          :alt="`${item.name}`" />
        <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_000A.jpg`"
          :alt="`${item.name}`" />
      </div>
      <div class="product-info pb-0">
        <div class="product-name">{{ item.brand }}</div>
        <div v-if="item.band_sub" class="product-name-sub">{{ item.band_sub }}</div>
        <div v-if="item.band_sub2" class="product-name-sub2">{{ item.band_sub2 }}</div>
        <div v-if="item.text" class="product-name-sub2 mt-2">{{ item.text }}</div>
        <p v-if="item.sale_price" class="product-price red-text mb-0">{{ item.sale_price }}</p>
        <p v-if="item.price" :class="[item.sale_price ? 'product-price line-through' : 'product-price']">{{ item.price }}
        </p>
       <!-- <a :href="`${item.url}`" class="btn-shop button">Shop Now</a>-->
      </div>
    </a>
  </div>
</template>
<script>
import "./HomeProducts.scss";
export default {
  name: "HomeProducts",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      Items: [
        {
          imageOne: "8056262247082",
          brand: "Versace - VE4480U",
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Versace/VE4480U-8056262247082",
          text: ""
        },
        {
          imageOne: "8056597207812",
          brand: "Versace - VE4361",
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Versace/VE4361--8056597207812",
          text: ""
        },
        {
          imageOne: "8056597922371",
          brand: "Versace - VE2264",
          price: "",
          sale_price: "",
          url: "sunglasshut-sa/Versace/VE2264-8056597922371",
          text: ""
        },
        {
          imageOne: "8056597922265",
          brand: "Versace - VE4459",
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Versace/VE4459-8056597922265",
          text: ""
        },
        {
          imageOne: "8053672947397",
          brand: "Versace - VE4361",
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Versace/VE4361--8053672947397",
          text: ""
        },
        {
          imageOne: "8056262222447",
          brand: "Versace - VE4480U",
          price: "",
          sale_price: "",
          url: "/sunglasshut-sa/Versace/VE4480U-8056262222447",
          text: ""
        }
      ],
    };
  },
};
</script>
